const INFO = {
	main: {
		title: "Shiguo Feng | Homepage",
		name: "shiguo",
		email: "shiguo@ualberta.ca",
		logo: "../avatar.jpg",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/shiguo-Feng",
		linkedin: "https://www.linkedin.com/in/shiguo-feng-7862a5263/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		// title: "Fullstack web Dev & Debug Detective",
		title: "",
		description:
			"I'm a full-stack developer skilled in Spring Boot, Django, and React, focused on building high-performance web applications with clean, efficient code."
			// "I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "About me",
		// title: "Full-stack web Dev & Debug Detective",
		description: `
			During my degree, I deeply immersed myself in back-end logic, algorithms, and Artificial Intelligence.
			While my academic focus was largely on the back-end, I've always been captivated by intuitive UI/UX designs.
			This passion led me into a career as a full-stack developer, skilled with front-end technologies like React, and back-end frameworks such as Django and SpringBoot.
			`,
	},

	articles: {
		title: "I’ve learned that writing is one of the best ways to truly understand and share what I’ve learned.",
		description:
			"A chronological collection of my long-form thoughts on programming, design patterns, mathematics, project blogs, and more.",
	},

	projects: [
		{
			title: "Auto Code Review",
			description:
				"An automated code review tool integrating GitHub Actions and OpenAI to streamline the review process.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/java/java.png",
			linkText: "View Project Usage",
			link: "https://github.com/shiguo-Feng/chatgpt-code-review-log",
		},

		{
			title: "Blog",
			description:
				"A fully-featured blog platform with a comment system and secure authentication using OAuth2. ",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/typescript/typescript.png",
			linkText: "Read Blogs",
			link: "https://blog.shiguofeng.com/",
		},

		{
			title: "Habit Tracker",
			description:
				" Collaborated on a team to create an Android habit-tracking app, focusing on user-friendly progress monitoring.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/java/java.png",
			linkText: "View Project",
			link: "https://github.com/CMPUT301F21T45/GoAlog",
		},
	],
	featureProjects: [
		{
			title: "GPTHUB",
			thumbnail:"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/gpthub_cover_1.png",
			description: `
			  A responsive SaaS platform built with Next.js, TypeScript, and a microservices architecture using SpringBoot and Domain-Driven Design.
			  
			  It integrates seamless API interactions with popular AI platforms and a Stripe-powered e-commerce system.
			`,
			liveLink: "https://gpthub.shiguofeng.com",
			techTags: [
				"SpringBoot",
				"JUnit",
				"Domain-Driven Design",
				"React/Typescript",
				"Nginx",
				"OpenAI/Google AI/Claude AI Api",
				"MySQL",
				"Stripe",
				"Discord Bot",
				"SCSS"
			],
			images: [
				"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/gpthub_cover_1.png",
				"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/gpthub_cover_2.png",
				// "https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/gpthub_cover_3.png",
				// "https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/gpthub_cover_4.png",
				// "https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/gpthub_cover_5.png",
			]
		},
		{
			title: "Tracer",
			thumbnail:"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/tracer_cover_1.png",
			description: `
			  A Issue Tracker enabling seamless, isolated management of documents, files, and issues.
			
			  Elevated user interactions by minimizing front-end refreshes and optimizing data filtering, while ensuring secure, efficient operations.
			`,
			liveLink: "https://shiguofeng.com/tracer/",
			techTags: [
				"Django/DRF",
				"React with RTK/RTK Query",
				"Docker-compose",
				"Redis",
				"AWS EC2/S3",
				"PostgreSQL",
			],
			images: [
				"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/tracer_cover_1.png",
				"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/tracer_cover_2.png",
				"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/tracer_cover_3.png",
				"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/tracer_cover_4.png",
				"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/tracer_cover_5.png",
			]
		},
		{
			title: "G19 Price Estimator",
			thumbnail:"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/G19_cover_1.png",
			description: `
				A responsive web application for G19 Graphics that guides users through a step-by-step process to obtain final pricing and schedule appointments.
				
				Integrated an admin system for managing quotations and appointments.
				
    			*Note: The data below is test data.*
				`,
			liveLink: "https://www.g19graphics.com/paintprotectionfilm/",
			techTags: [
				"SpringBoot",
				"Spring Security",
				"React",
				"Redis",
				"RESTful API",
				"Nginx",
				"Docker-compose",
				"MySQL",
				"AWS SNS/SES",
			],
			images: [
				"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/G19_cover_1.png",
				"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/G19_cover_2.png",
				"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/G19_cover_3.png",
				"https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/G19_cover_4.png",
			]
		},
	],
	softwareSkills: [
		"Js/Ts",
		"React",
		"git",
		"sql-database",
		"java",
		"python",
		"docker",
		"aws",
		"html-5",
		"css3",
		"sass",
	],

};

export default INFO;
